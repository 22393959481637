import { windowSize } from '@/utils'

export default {
    props: {
        item: {
            type: Object,
            default: null
        },
        fullscreen: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        iframeLoadableStyles() {
            return { opacity: this.loading ? 0 : 1 }
        },
        iframePreloaderStyles() {
            return { opacity: this.loading ? 1 : 0 }
        },
    },
    data() {
        return {
            loading: true,
            componentKey: 1,
            winWidth: 0,
            winHeight: 0,
            orientationAlbum: false,
            orientationPortrait: false
        }
    },
    mounted() {
        this.winWidth = windowSize.width()
        this.winHeight = windowSize.height()

        this.initOrientation()

        window.addEventListener('resize', () => {
            this.componentKey++

            this.winWidth = windowSize.width()
            this.winHeight = windowSize.height()

            if(this.winResize) {
                this.winResize()
            }
        })
    },
    methods: {
        initOrientation() {
            if(this.winWidth > this.winHeight) {
                this.orientationAlbum = true
                this.orientationPortrait = false
            } else {
                this.orientationAlbum = false
                this.orientationPortrait = true
            }
        },
        iframeOnLoad(e) {
            this.loading = false
        }
    }
}
