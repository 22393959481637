<template>
    <component
        v-if="item"
        :key="componentKey"
        :is="componentName"
        :item="item"
        :fullscreen="fullscreen"
    />
</template>

<script>
import { isAudio, isImage, isMSDocument, isPDF, isYoutubeLink } from '@apps/media/tools'
import mediaViewMixin from '@apps/media/mixins/mediaViewMixin'

export default {
    name: 'MediaView',
    mixins: [ mediaViewMixin ],
    components: {
        'youtube-view': () => import('./YoutubeView'),
        'link-view': () => import('./LinkView'),
        'gdoc-view': () => import('./GdocView'),
        'pdf-view': () => import('./PDFView'),
        'audio-view': () => import('./AudioView'),
        'image-view': () => import('./ImageView')
    },
    computed: {
        componentName() {
            if(!this.item) {
                return false
            }

            if(isYoutubeLink(this.item.src)) {
                return 'youtube-view'
            }

            if(isPDF(this.item.src) || isMSDocument(this.item.src)) {
                return 'gdoc-view'
            }

            if(isAudio(this.item.src)) {
                return 'audio-view'
            }

            if(isImage(this.item.src)) {
                return 'image-view'
            }

            return 'link-view'
        }
    },
    watch: {
        componentName() {
            this.componentKey++
        }
    },
    data() {
        return {
            componentKey: 0
        }
    }
}
</script>

<style lang=scss>

</style>
